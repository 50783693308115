import "core-js/modules/es.array.push.js";
import { NavBar, Icon } from 'vant';
export default {
  name: 'BackOff',
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    url: {
      typeof: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    title: {
      typeof: String,
      default: ''
    }
  },
  created: async function () {},
  methods: {
    onClickLeft(url) {
      // 清除地址的本地缓存
      localStorage.removeItem('selectedAddressId');
      window.location.href = this.$route.query.back_url;
    },
    onHomeClick() {
      // 清除地址的本地缓存
      localStorage.removeItem('selectedAddressId');
      this.$router.push({
        path: '/'
      });
    }
  }
};