var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "okr-block-clipboard",
    staticStyle: {
      "padding-left": "3vw",
      "padding-right": "3vw"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Replacement & Refund"
    }
  }), _vm._m(0), _vm._m(1), _vm._m(2), _c('p', [_vm._v(" Also please be informed that placing an order means that you accept all the terms mentioned below: ")]), _c('p', [_vm._v(" At this time we do not offer Return in any case, we just offer replacement. If your item is defective, you don't need to return the original item and we will resend you a replacement for free. ")]), _vm._m(3), _vm._m(4), _vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10), _vm._m(11), _vm._m(12), _c('p', [_vm._v(" Please allow the 20% difference between advertised images and the actual item you received as sometimes advertised images may slightly different from actual item in terms of color due to the lighting during photo shooting or the monitor's display ")]), _vm._m(13), _vm._m(14), _vm._m(15), _vm._m(16), _vm._m(17), _vm._m(18), _vm._m(19), _c('p', [_vm._v(" Our support team will review the photos/videos you send of the defective item and, if your item is eligible, we will resend the product to you for no additional cost to you. In the rare case that sending a replacement product is not practical, we will reimburse you the full cost of the eligible product (including shipping costs). ")]), _c('br'), _c('br')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('h1', [_vm._v("Replacement & Refund")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" More than anything, we want you to be happy with your purchase! If something doesn’t look or feel right, please email us at "), _c('a', {
    attrs: {
      "href": "mailto:udefine@caiseshi.cn",
      "target": "_blank"
    }
  }, [_vm._v(" udefine@caiseshi.cn ")]), _vm._v(" and we’ll make it right! ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" Please be reminded that our policy "), _c('b', [_vm._v("lasts 30 days for replacement/refund request only")]), _vm._v(". If 30 days have gone by since your purchase, unfortunately we can’t offer you a replacement or refund. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" Due to hygiene reasons and the fact that our products are customized, "), _c('b', [_vm._v("we do not exchange or issue full refund unless an item you received has a major problem")]), _vm._v(". This is when the item is: ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Significantly different from the description or the preview shown to you; ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Damaged due to the fault of our factory or the carrier; ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Not what you ordered; ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('i', [_c('b', [_vm._v("Please be noted that with other cases (not mentioned above) we only offer partial refund (excluding shipping fee)")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b', [_vm._v("NOTE:")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" We "), _c('b', [_vm._v("DO NOT")]), _vm._v(" offer refunds or exchanges on any products purchased during a sale or any promotion. ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" We typically "), _c('b', [_vm._v("DO NOT")]), _vm._v(" accept refunds or replacement due to customer's mistakes such as: "), _c('ul', [_c('li', {}, [_vm._v(" Incorrect selection of sizes, designs, colors, etc. ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Failing to preview the design carefully to see if it's well set (as all of our printing files are automatically generated exactly as same as the preview on our website) ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" So please "), _c('b', [_vm._v("review your order carefully")]), _vm._v(" before you check out to ensure your order is correct. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" To file a claim, please send us an email to "), _c('a', {
    attrs: {
      "href": "mailto:udefine@caiseshi.cn",
      "target": "_blank"
    }
  }, [_vm._v(" udefine@caiseshi.cn ")]), _vm._v(" and include the following information (this is a must): ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Order number ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" Video/ photo of the defective product "), _c('ul', [_c('li', {}, [_vm._v(" If your claim is in regards to a printing error, please include photographs of the error. ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" If your claim is in regards to a print size or placement issue, please include a measuring tape, stick, or ruler in the photographs of affected garments. ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', {}, [_vm._v(" If some of your items are missing, please provide us an image of the received package's cover including the detailed shipping label so we can forward to our production department to investigate the problem ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', [_vm._v(" Full delivery address ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', [_c('li', [_vm._v(" Contact telephone number ")])]);
}];
export { render, staticRenderFns };