var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-nav-bar', {
    staticStyle: {
      "background-color": "aliceblue",
      "widows": "100vw"
    },
    attrs: {
      "title": _vm.title,
      "left-text": "Back",
      "left-arrow": ""
    },
    on: {
      "click-left": function ($event) {
        return _vm.onClickLeft(_vm.url);
      }
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('van-icon', {
          attrs: {
            "name": "wap-home-o",
            "size": "25"
          },
          on: {
            "click": _vm.onHomeClick
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };